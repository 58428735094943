/*
* Flipper
*/
.flip-container {
  -webkit-perspective: 1000;
     -moz-perspective: 1000;
          perspective: 1000;
  margin: auto;
}
@media (min-width: 990px) {
  .flip-container:hover .back {
    -webkit-transform: rotateY(0deg);
       -moz-transform: rotateY(0deg);
            transform: rotateY(0deg);
  }
  .flip-container:hover .front {
    -webkit-transform: rotateY(180deg);
       -moz-transform: rotateY(180deg);
            transform: rotateY(180deg);
  }
}
.flip-container, .front, .back {
  width: 100%;
  height: 500px;
  -webkit-border-radius: 10px;
     -moz-border-radius: 10px;
          border-radius: 10px;
  overflow: hidden;
}
.flipper {
  -webkit-transition: 0.6s;
     -moz-transition: 0.6s;
       -o-transition: 0.6s;
          transition: 0.6s;
	-webkit-transform-style: preserve-3d;
	   -moz-transform-style: preserve-3d;
	        transform-style: preserve-3d;
	position: relative;
}
.front, .back {
	-webkit-backface-visibility: hidden;
	   -moz-backface-visibility: hidden;
	        backface-visibility: hidden;
	position: absolute;
	top: 0;
	left: 0;
  -webkit-transition: 0.4s;
     -moz-transition: 0.4s;
       -o-transition: 0.4s;
          transition: 0.4s;
  -webkit-transform-style: preserve-3d;
     -moz-transform-style: preserve-3d;
          transform-style: preserve-3d;
}
.front {
	z-index: 2;
}
.back {
	-webkit-transform: rotateY(180deg);
	   -moz-transform: rotateY(180deg);
	        transform: rotateY(180deg);
	background: #f8f8f8;
  padding: 30px;
  background-repeat: no-repeat;
  -moz-background-size: cover;
    -o-background-size: cover;
       background-size: cover;
}
.back:after {
  content: "";
  background: RGBA(63, 57, 59, 0.9);
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  z-index: 2;
}
.back * {
  z-index: 3;
  position: relative;
}
/* For mobile devices*/
.flip-container.open .flipper {
  -webkit-transform: rotateY(180deg);
     -moz-transform: rotateY(180deg);
          transform: rotateY(180deg);
}

/*
* Transition
*/
.transition {
  -webkit-transition: all 0.2s ease-in;
     -moz-transition: all 0.2s ease-in;
       -o-transition: all 0.2s ease-in;
          transition: all 0.2s ease-in;
}
.transition:hover {
  -webkit-transition: all 0.2s ease-out;
     -moz-transition: all 0.2s ease-out;
       -o-transition: all 0.2s ease-out;
          transition: all 0.2s ease-out;
}

/*
* Parallax background
*/
.parallax {
  background-attachment: fixed !important;
}